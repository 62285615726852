export interface UserContact {
    id: number;
    isVerified: boolean;
    createdAt: Date;
    updatedAt: Date;
    contactType: string;
    contact: string;
    verifiedAt?: Date | null;
    externalId: string;
}

export function mapUserContact(data: any): UserContact {
    return {
        id: data.id || 0,
        isVerified: data.is_verified || false,
        createdAt: new Date(data.created_at),
        updatedAt: new Date(data.updated_at),
        contactType: data.contact_type || "",
        contact: data.contact || "",
        verifiedAt: data.verified_at || null,
        externalId: data.external_id || "",
    };
}
