export const environment = {
    production: false,
    app: {
        googleTagManagerId: "GTM-T9GCQM4S",
        posthogId: "",
        sentryDsn: "",
        hereMapsApiKey: "SNYwAovO4Iy362mTMfiI89zdQfXW6Wy-i5noaVT2xuQ",
        agentPhoneNumber: "xxxxx",
    },
    api: {
        baseUrl: "https://pflegepiraten-dev.fly.dev/api/v1/",
    },
};
