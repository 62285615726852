import { Routes } from "@angular/router";
import { HomeComponent } from "./modules/home/home.component";
import { MatchDetailsComponent } from "./modules/match/match-details/match-details.component";
import { MatchAppliedComponent } from "./modules/match/match-applied/match-applied.component";
import { JobPreviewComponent } from "./modules/job/job-preview/job-preview.component";
import { ApplicantRegistrationFinishedComponent } from "./modules/applicant/applicant-registration-finished/applicant-registration-finished.component";
import { PhoneVerifyComponent } from "./modules/phone/phone-verify/phone-verify.component";
import { PhoneAddComponent } from "./modules/phone/phone-add/phone-add.component";
import { HomeRiskComponent } from "./modules/home/home-risk/home-risk.component";
import { HomeRejectComponent } from "./modules/home/home-reject/home-reject.component";

const routeConfig: Routes = [
    {
        path: "verify/:extId/:contactId",
        component: PhoneVerifyComponent,
    },
    {
        path: ":extId",
        children: [
            {
                path: "",
                component: HomeComponent,
                data: { animation: "isLeft" },
                title: " länk | Deine passenden Jobvorschläge",
            },
            {
                path: "reject",
                component: HomeRejectComponent,
                title: "Absage",
            },
            {
                path: "agent-contact",
                component: HomeRiskComponent,
                title: "Betreuer kontaktieren",
            },
            {
                path: "match/:matchExtId",
                children: [
                    {
                        path: "",
                        component: MatchDetailsComponent,
                        data: { animation: "isRight" },
                        title: "Match Details",
                    },
                    {
                        path: "applied",
                        component: MatchAppliedComponent,
                        title: "Accepted Match",
                    },
                    {
                        path: "appliedAfterRegistration",
                        component: MatchAppliedComponent,
                        title: "Accepted Match",
                    },
                ],
            },
            {
                path: "registered",
                component: ApplicantRegistrationFinishedComponent,
                title: "Registered",
            },

            {
                path: "phone/add",
                component: PhoneAddComponent,
                title: "Telefonnummer hinzufügen",
            },
        ],
    },
    {
        path: "preview/:jobExtId",
        component: JobPreviewComponent,
        title: "Job Preview",
    },
];

export default routeConfig;
