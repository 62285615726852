import { Injectable, isDevMode } from "@angular/core";
import { MatchApplier } from "../../app/model";
import { mapMatchApplier } from "src/app/model/matchApplier";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";
import { Router } from "@angular/router";
import {
    PhoneVerificationService,
    PhoneNumberType,
} from "./phone-number.service";
import { User } from "src/app/model/user";

@Injectable({
    providedIn: "root",
})
export class MatchService {
    constructor(
        private userService: UserService,
        private router: Router,
        private phoneVerificationService: PhoneVerificationService
    ) {
        if (isDevMode()) {
            console.log("MatchService");
        }
    }
    private matchApplier: BehaviorSubject<MatchApplier | null> =
        new BehaviorSubject<MatchApplier | null>(null);

    public get matchApplier$() {
        return this.matchApplier.asObservable();
    }

    public get matchApplierValue() {
        return this.matchApplier.value;
    }

    public get isInited() {
        return this.matchApplier.value !== null;
    }

    saveData(extId: string, matchApplier: MatchApplier) {
        localStorage.setItem(
            "matchApplier",
            JSON.stringify({ extId: extId, data: matchApplier })
        );
    }

    getMatchListPosition(matchExtId: string): number | undefined {
        return this.matchApplier.value?.matches
            .filter((m) => m.appliedAt == null)
            .findIndex((match) => match.extId == matchExtId);
    }

    private async getAllMatches(extId: string): Promise<MatchApplier> {
        try {
            const response = await fetch(
                `${environment.api.baseUrl}accounts/applier/public/${extId}`
            );
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            // redirect if received extId differs
            // this is the case when passing applier.extID but user.external_id is expected
            const externalId = data["applier"]["user"]["external_id"];
            if (externalId && externalId != extId) {
                this.userService.navigateToNewExtId(externalId);
            }

            const matchApplier: MatchApplier = mapMatchApplier(data);
            this.matchApplier.next(matchApplier);
            this.saveData(extId, matchApplier);
            return matchApplier;
        } catch (error) {
            console.error("Error occurred:", error);
            throw error;
        }
    }

    async getMatchApplierData(extId: string): Promise<MatchApplier> {
        const storedData = localStorage.getItem("matchApplier");
        if (storedData) {
            const matchApplierData = JSON.parse(storedData);
            if (matchApplierData.extId == extId) {
                var matchApplierObject: MatchApplier = matchApplierData.data;
                //  parse date strings to Date objects
                matchApplierObject.applier.user.contacts.forEach((contact) => {
                    contact.createdAt = new Date(contact.createdAt);
                    contact.updatedAt = new Date(contact.updatedAt);
                    if (contact.verifiedAt) {
                        contact.verifiedAt = new Date(contact.verifiedAt);
                    }
                });
                this.matchApplier.next(matchApplierObject);
            }
        }
        try {
            const matchApplier = await this.getAllMatches(extId);

            // TODO:Put this back in after testing phase to enforce verification of users
            // this.checkVerificationStatus(matchApplier.applier.user);
            return matchApplier;
        } catch (error) {
            console.error("Error occurred:", error);
            throw error;
        }
    }

    /**
     * Checks the verification status of the match applier's contacts.
     *
     * This method filters the contacts of the match applier to find verified contacts.
     * If no verified contacts are found, it sorts the contacts by creation date and filters
     * for WhatsApp contacts. If no WhatsApp contacts are found, it logs a message and returns.
     *
     * If the user is not already on the verify page, it sends a phone verification code
     * via WhatsApp to the latest WhatsApp contact and navigates to the verify page.
     *
     * @param {User} user - The match applier whose contacts are being checked.
     */
    checkVerificationStatus(user: User) {
        const contacts = user.contacts;
        const verifiedContacts = contacts.filter((c) => c.isVerified);
        if (verifiedContacts.length == 0) {
            var whatsappContacts = contacts
                .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                .filter((c) => c.contactType == "WHATSAPP");
            if (whatsappContacts.length == 0) {
                console.log("No whatsapp contacts found");
                return;
            }
            const latestWhatsappContactExtId = whatsappContacts[0].externalId;

            // check if user is already on the verify page
            if (this.router.url.includes("verify")) {
                return;
            }
            const userExtId = this.userService.getExtId();

            this.phoneVerificationService.sendPhoneVerificationCode(
                userExtId ?? "",
                latestWhatsappContactExtId,
                PhoneNumberType.Whatsapp
            );
            this.router.navigate([
                "verify",
                userExtId,
                latestWhatsappContactExtId,
            ]);
        }
    }

    async acceptMatch(matchExtId: string): Promise<any> {
        const response = await fetch(
            `${environment.api.baseUrl}match/matchlist/public/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify({
                    extID: matchExtId,
                    status: 5,
                }),
            }
        );
        return response;
    }
}
