import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { MatchListService } from "src/shared/services/match-list.service";
import { MatchService } from "src/shared/services/match.service";
import {
    PhoneVerificationService,
    VerifcationSendResult,
    PhoneNumberType,
    VerificationCodeValidationResult,
} from "src/shared/services/phone-number.service";

@Component({
    selector: "app-phone-verify",
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDividerModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: "./phone-verify.component.html",
    styleUrl: "./phone-verify.component.scss",
})
export class PhoneVerifyComponent {
    userExtId: string = "";
    contactExtId: string = "";
    formGroup!: FormGroup;
    verificationResult: boolean | null = null;
    errorMessage: string | null = null;
    loading = false;
    initialLoading = true;
    success = false;
    nextScreen = "";
    codeSentTo: string | null = null;
    codeSentVia: string | null = null;

    constructor(
        private route: ActivatedRoute,
        private phoneVerificationService: PhoneVerificationService,
        private router: Router,
        private matchService: MatchService
    ) {}

    async handleCodeSend(phoneNumberType = PhoneNumberType.Whatsapp) {
        this.loading = true;
        this.errorMessage = null;
        var response =
            await this.phoneVerificationService.sendPhoneVerificationCode(
                this.userExtId,
                this.contactExtId,
                phoneNumberType
            );

        switch (response) {
            case VerifcationSendResult.CodeSent: {
                this.phoneVerificationService
                    .getContactDetails(this.userExtId, this.contactExtId)
                    .then((contact) => {
                        this.codeSentTo = contact.contact;
                        var contactType = contact.contactType;
                        if (contactType === "WHATSAPP") {
                            contactType = "WhatsApp";
                        } else if (contactType === "PHONE") {
                            contactType = "SMS";
                        }
                        this.codeSentVia = contactType;
                    });
                break;
            }
            case VerifcationSendResult.ContactAlreadyVerified: {
                this.success = true;
                // redirect to the next page after timeout
                setTimeout(() => {
                    this.goHome();
                }, 2000);
                break;
            }
            case VerifcationSendResult.Failed: {
                break;
            }
            case VerifcationSendResult.ResendIntervalNotPassed: {
                this.errorMessage =
                    "Zu viele Nachrichten versendet. Bitte warten Sie einen Moment.";
            }
        }
        this.loading = false;
    }

    async ngOnInit() {
        // parse route params
        this.route.params.subscribe((params) => {
            this.userExtId = params["extId"];
            this.contactExtId = params["contactId"];
        });

        //get query params
        this.route.queryParams.subscribe((params) => {
            var nextScreenParam = params["result"] ?? "danke-app";
            if (nextScreenParam === "danke-app") {
                this.nextScreen = "";
            } else if (nextScreenParam === "danke-risk") {
                this.nextScreen = "agent-contact";
            } else {
                this.nextScreen = "reject";
            }
        });

        // get "initialsend" key from local storage
        let intialCodeWasSent =
            localStorage.getItem("intialCodeWasSent") === "true";
        if (!intialCodeWasSent) {
            await this.handleCodeSend();
            localStorage.setItem("intialCodeWasSent", "true");
        }
        // create form group
        this.formGroup = new FormGroup({
            code: new FormControl("", [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
            ]),
        });
        var res = await this.initializeContactInfo(
            this.userExtId,
            this.contactExtId
        );
        if (!res) {
            // wrong contact ext id in url, redirect to home - this should fetch the correct contact and then redirect here again
            this.goHome();
        }
    }

    async initializeContactInfo(userExtId: string, contactExtId: string) {
        try {
            var contact = await this.phoneVerificationService.getContactDetails(
                userExtId,
                contactExtId
            );

            this.codeSentTo = contact.contact;
            var contactType = contact.contactType;
            if (contactType === "WHATSAPP") {
                contactType = "WhatsApp";
            } else if (contactType === "PHONE") {
                contactType = "SMS";
            }
            this.codeSentVia = contactType;
            this.initialLoading = false;
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async onSubmit() {
        this.errorMessage = null;
        this.loading = true;
        if (this.formGroup.valid) {
            var code = this.formGroup.get("code")?.value;
            var result =
                await this.phoneVerificationService.verifyCodeWithBackend(
                    code,
                    this.userExtId,
                    this.contactExtId
                );
            this.verificationResult =
                result === VerificationCodeValidationResult.CodeValid;
            if (this.verificationResult) {
                this.success = true;
                // redirect to the next page after timeout
                setTimeout(() => {
                    this.goHome();
                }, 2000);
            } else {
                if (result === VerificationCodeValidationResult.CodeInvalid) {
                    this.errorMessage =
                        "Der angegebene Code ist nicht korrekt.";
                } else if (
                    result === VerificationCodeValidationResult.TooManyAttempts
                ) {
                    this.errorMessage =
                        "Zu viele Versuche. Bitte versuchen Sie es später erneut.";
                }
            }
        }

        this.loading = false;
    }

    sendSmsCode() {
        this.handleCodeSend(PhoneNumberType.Sms);
    }

    goHome() {
        if (this.nextScreen === "") {
            this.router.navigateByUrl(`/${this.userExtId}`);
        } else {
            this.router.navigateByUrl(`/${this.userExtId}/${this.nextScreen}`);
        }
    }

    addNewPhone() {
        this.router.navigateByUrl(`${this.userExtId}/phone/add`);
    }
}
