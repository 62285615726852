import { Injectable } from "@angular/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Injectable({
    providedIn: "root",
})
export class AnalyticsService {
    adStorageConsent: boolean;
    constructor(private gtmService: GoogleTagManagerService) {
        this.gtmService.addGtmToDom();
        this.adStorageConsent = false;
        this.loadAdConsent();
    }
    /**
     * Loads the ad consent status from the data layer.
     */
    loadAdConsent() {
        // Consent Status
        const dataLayer = this.gtmService.getDataLayer();
        const consentEvents = dataLayer.filter(
            (event: any) => event?.length > 0 && event[0] === "consent"
        );
        const latestConsent =
            consentEvents.length > 0
                ? consentEvents[consentEvents.length - 1]
                : null;
        this.adStorageConsent = latestConsent?.[2]?.ad_storage === "granted";
    }
    /**
     * Tracks an event with the given name and data.
     * @param eventName The name of the event.
     * @param data The data associated with the event.
     * @example
     *  this.analyticsService.trackEvent("registration-dialog-open", {
     *       applier_extId: this.userService.getExtId(),
     *       source: "profile-avatar",
     *  });
     */
    trackEvent(eventName: string, data: object) {
        this.loadAdConsent();
        if (!this.adStorageConsent) {
            return;
        }
        // Google Tag Manager
        const gtmTag = {
            event: eventName,
            ...data,
        };
        this.gtmService.pushTag(gtmTag);
    }
}
