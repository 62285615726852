import { Component } from "@angular/core";

@Component({
    selector: "app-home-reject",
    standalone: true,
    imports: [],
    templateUrl: "./home-reject.component.html",
    styleUrl: "./home-reject.component.scss",
})
export class HomeRejectComponent {}
