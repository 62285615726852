<div class="main" style="display: flex">
    <div
        class="column"
        style="
            padding: 16px;
            gap: 8px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        "
    >
        @if (success) {
        <div
            style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 8px;
            "
        >
            <p style="text-align: center; font-size: 22px; font-weight: 600">
                Telefonnummer erfolgreich verifiziert!
            </p>

            <mat-icon fontIcon="check_circle" class="sized-icon" />
            <p style="text-align: center">Du wirst in Kürze weitergeleitet.</p>
            <button mat-raised-button (click)="this.goHome()">
                Direkt weiter
            </button>
        </div>
        } @else {
        <img
            src="../../assets/images/logo_full.svg"
            style="text-align: center; height: 35px"
        />
        <p style="font-size: 24px; font-weight: 500; text-align: center">
            Verifizierungscode
            {{
                this.codeSentVia ? "aus " + this.codeSentVia + " " : ""
            }}eingeben
        </p>
        @if (this.initialLoading) {
        <div class="column-center">
            <mat-spinner diameter="24" />
        </div>
        } @else {
        <p style="text-align: center">
            Wir haben einen 4-stelligen Code an
            {{
                this.codeSentTo
                    ? this.codeSentTo + " per " + this.codeSentVia
                    : "deine Telefonnummer"
            }}
            gesendet.
        </p>
        <form (ngSubmit)="onSubmit()" [formGroup]="this.formGroup">
            <div class="column" style="gap: 8px">
                <mat-form-field
                    appearance="fill"
                    class="rounded-border"
                    style="width: 100%"
                    subscriptSizing="dynamic"
                >
                    <mat-label class="bigger-input">Code</mat-label>
                    <input
                        matInput
                        inputmode="numeric"
                        autocomplete="one-time-code"
                        pattern="\d{4}"
                        required
                        l
                        formControlName="code"
                    />
                    <mat-error>Der Code muss aus 4 Zahlen bestehen.</mat-error>
                </mat-form-field>
                <div *ngIf="errorMessage" class="error-message">
                    {{ errorMessage }}
                </div>

                <button mat-flat-button type="submit" [disabled]="this.loading">
                    Verifizieren
                </button>
            </div>
        </form>
        <mat-divider style="margin-top: 12px; margin-bottom: 12px" />
        <div class="row-center">
            <p>Keinen Code erhalten?</p>
            <button
                mat-button
                (click)="handleCodeSend()"
                [disabled]="this.loading"
            >
                Erneut senden
            </button>
        </div>
        <div class="row-center">
            <p>Kein Whatsapp?</p>
            <button
                mat-button
                (click)="sendSmsCode()"
                [disabled]="this.loading"
            >
                SMS senden
            </button>
        </div>
        <div class="row-center">
            <p>Falsche Nummer?</p>
            <button
                mat-button
                (click)="this.addNewPhone()"
                [disabled]="this.loading"
            >
                Telefonnummer ändern
            </button>
        </div>
        } }
    </div>
</div>
