import { Injectable } from "@angular/core";
import { UserContact, mapUserContact } from "src/app/model/userContact";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class PhoneVerificationService {
    constructor() {}

    /**
     * Verifies the provided verification code with the backend service.
     *
     * @param code - The verification code to be validated.
     * @param userId - The ID of the user whose contact is being verified.
     * @param userContactExtId - The external ID of the user's contact.
     * @returns A promise that resolves to a `VerificationCodeValidationResult` indicating the result of the verification.
     *
     * @throws Will log an error and return `VerificationCodeValidationResult.CodeInvalid` if the verification process fails.
     *
     * Possible return values:
     * - `VerificationCodeValidationResult.CodeValid`: The verification code is valid.
     * - `VerificationCodeValidationResult.CodeInvalid`: The verification code is invalid.
     * - `VerificationCodeValidationResult.TooManyAttempts`: The maximum number of verification attempts has been reached.
     */
    async verifyCodeWithBackend(
        code: string,
        userId: string,
        userContactExtId: string
    ): Promise<VerificationCodeValidationResult> {
        try {
            const url = `${environment.api.baseUrl}accounts/user/${userId}/contacts/${userContactExtId}/confirm-verification-code/`;
            const result = await fetch(url, {
                method: "PATCH",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    verification_code: code,
                }),
            });
            if (!result) {
                console.error(`Verifying code failed`);
                return VerificationCodeValidationResult.CodeInvalid;
            }
            if ([200, 201, 203].includes(result.status)) {
                return VerificationCodeValidationResult.CodeValid;
            }
            if (result.status == 400) {
                var data = await result.json();
                var message = data["detail"];
                if (message && message == "Contact is already verified.") {
                    return VerificationCodeValidationResult.CodeValid;
                } else if (
                    message &&
                    message ==
                        "Maximum number of verification attempts reached."
                ) {
                    return VerificationCodeValidationResult.TooManyAttempts;
                }
            }
            // Fallback
            return VerificationCodeValidationResult.CodeInvalid;
        } catch (e) {
            console.error(e);
            return VerificationCodeValidationResult.CodeInvalid;
        }
    }

    /**
     * Sends a phone verification code to a user's contact.
     *
     * @param userExtId - The external ID of the user.
     * @param contactExtId - The external ID of the contact.
     * @param verificationChannel - The channel through which the verification code will be sent. Defaults to `VerificationChannel.Whatsapp`.
     * @returns A promise that resolves to a `VerifcationSendResult` indicating the result of the operation.
     *
     * @throws Will log an error and return `VerifcationSendResult.Failed` if the request fails.
     */
    async sendPhoneVerificationCode(
        userExtId: string,
        contactExtId: string,
        phoneNumberType: PhoneNumberType = PhoneNumberType.Whatsapp
    ): Promise<VerifcationSendResult> {
        try {
            const url = `${environment.api.baseUrl}accounts/user/${userExtId}/contacts/${contactExtId}/send-verification-code/`;
            const result = await fetch(url, {
                method: "PATCH",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    verification_channel: phoneNumberType,
                }),
            });
            if (!result) {
                console.error(`Sending a verification code failed`);
                return VerifcationSendResult.Failed;
            }
            if ([200, 201, 203].includes(result.status)) {
                return VerifcationSendResult.CodeSent;
            }
            if (result.status == 400) {
                var data = await result.json();
                var message = data["detail"];
                if (message && message == "Contact is already verified.") {
                    return VerifcationSendResult.ContactAlreadyVerified;
                }
                if (
                    message &&
                    message ==
                        "Verification code resend interval has not passed."
                ) {
                    return VerifcationSendResult.ResendIntervalNotPassed;
                }
            }
            // Fallback
            return VerifcationSendResult.Failed;
        } catch (e) {
            console.error(e);
            return VerifcationSendResult.Failed;
        }
    }

    /**
     * Adds a phone number for a user identified by their external ID.
     *
     * @param userExtId - The external ID of the user whose phone number will be added
     * @param phoneNumber - The new phone number to be associated with the user.
     * @returns A promise that resolves to the new contact external ID as a string.
     * @throws Will throw an error if the request fails or if the status code is unexpected.
     *
     * @example
     * ```typescript
     * const newContactExtId = await addPhoneNumber('usr12ha92ka', '+1234567890');
     * console.log(newContactExtId); // Outputs the new contact external ID
     * ```
     */
    async addPhoneNumber(
        userExtId: string,
        phoneNumber: string,
        phoneNumberType: PhoneNumberType = PhoneNumberType.Whatsapp
    ): Promise<string> {
        try {
            const url = `${environment.api.baseUrl}accounts/user/${userExtId}/contacts/`;
            const result = await fetch(url, {
                method: "POST",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    contact: phoneNumber,
                    contact_type: phoneNumberType,
                }),
            });
            if (!result) {
                console.error(`Sending a verification code failed`);
                throw new Error(
                    "Da ist etwas schief gelaufen. Bitte versuche es später erneut."
                );
            }
            if ([200, 201, 203].includes(result.status)) {
                const body = await result.json();
                const newContactExtId = body["external_id"];
                return newContactExtId;
            }
            if (result.status == 403) {
                throw new ContactChangeTooLongAfterSignUpError(
                    "Telefonnummer darf nur unmittelbar nach der Registrierung geändert werden."
                );
            }
            // Handle other unexpected statuses
            throw new Error(`Unexpected status code: ${result.status}`);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Fetches the contact details for a given user and contact external ID.
     *
     * @param userExtId - The external ID of the user.
     * @param contactExtId - The external ID of the contact.
     * @returns A promise that resolves to a `UserContact` object containing the contact details.
     * @throws Will throw an error if the fetch operation fails or if an unexpected status code is returned.
     */
    async getContactDetails(
        userExtId: string,
        contactExtId: string
    ): Promise<UserContact> {
        try {
            const url = `${environment.api.baseUrl}accounts/user/${userExtId}/contacts/${contactExtId}/`;
            const result = await fetch(url, {
                method: "GET",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!result) {
                console.error(`Fetching contact details failed`);
                throw new Error(
                    "Da ist etwas schief gelaufen. Bitte versuche es später erneut."
                );
            }
            if ([200, 201, 203].includes(result.status)) {
                const body = await result.json();
                const userContact: UserContact = mapUserContact(body);
                return userContact;
            }
            // Handle other unexpected statuses
            throw new Error(`Unexpected status code: ${result.status}`);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}

export enum PhoneNumberType {
    Whatsapp = "WHATSAPP",
    Sms = "SMS",
}

export enum VerifcationSendResult {
    CodeSent,
    ContactAlreadyVerified,
    Failed,
    ResendIntervalNotPassed,
}

export enum VerificationCodeValidationResult {
    CodeValid,
    CodeInvalid,
    TooManyAttempts,
}

export class ContactChangeTooLongAfterSignUpError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ContactChangeTooLongAfterSignUpError";
    }
}
