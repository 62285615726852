import { Injectable } from "@angular/core";
import { ConstantsService, ExtendedConstantMap } from "./constants.service";
import {
    FilterSortCriteria,
    GroupedFilterType,
    compareFilter,
} from "src/app/model/homeListFilter";
import { BehaviorSubject, combineLatest } from "rxjs";
import { MatchService } from "./match.service";
import { filterNonApplied } from "./match-list.service";

@Injectable({
    providedIn: "root",
})
export class ListFilterService {
    fullSetCareTypes: GroupedFilterType = {};
    initialized: boolean = false;

    private initialFilterSortCriteria$: FilterSortCriteria = {
        sort: "fit",
        careTypes: {},
        maxDistance: 40,
        positions: {},
        shiftTypes: new Set<number>(), // unused
    };

    public get initialFilterSortCriteria() {
        return structuredClone(this.initialFilterSortCriteria$);
    }

    private filtersSubject: BehaviorSubject<FilterSortCriteria> =
        new BehaviorSubject<FilterSortCriteria>(this.initialFilterSortCriteria);

    public get filtersSubject$() {
        return this.filtersSubject.asObservable();
    }

    public get filterSubjectValue(): FilterSortCriteria {
        return this.filtersSubject.getValue();
    }

    constructor(
        private constantsService: ConstantsService,
        private matchService: MatchService
    ) {
        // Initialize the initially selected
        combineLatest([
            this.matchService.matchApplier$,
            this.constantsService.constants$,
        ]).subscribe(([matchApplierValue, constants]) => {
            if (constants && !this.initialized) {
                var careTypeValues = this.getGroupedObject(
                    constants.extendedCareType
                );
                // Same for the positions
                var positionValues = this.getGroupedObject(
                    constants.extendedPosition
                );
                // Only display the care types as filter options that are avaiable to the user
                let filteredGroupedCareType: GroupedFilterType;
                let filteredPositionType: GroupedFilterType;
                // Same for the shift types
                let filteredShiftTypes: Set<number>;

                if (matchApplierValue) {
                    // Care types
                    var allCareTypes = matchApplierValue.matches
                        .filter(filterNonApplied)
                        .map((m) => m.job.careType[0]);
                    filteredGroupedCareType = {};

                    for (const parentLabel in careTypeValues) {
                        if (careTypeValues.hasOwnProperty(parentLabel)) {
                            const filteredChildren = careTypeValues[
                                parentLabel
                            ].filter((child) =>
                                allCareTypes.includes(child.id)
                            );
                            if (filteredChildren.length > 0) {
                                filteredGroupedCareType[parentLabel] =
                                    filteredChildren;
                            }
                        }
                    }

                    // Positions
                    var allPosition = matchApplierValue.matches
                        .filter(filterNonApplied)
                        .map((m) => m.job.position[0]);
                    filteredPositionType = {};

                    for (const parentLabel in positionValues) {
                        if (positionValues.hasOwnProperty(parentLabel)) {
                            const filteredChildren = positionValues[
                                parentLabel
                            ].filter((child) => allPosition.includes(child.id));
                            if (filteredChildren.length > 0) {
                                filteredPositionType[parentLabel] =
                                    filteredChildren;
                            }
                        }
                    }

                    // Shift types
                    var allWorkingHours = matchApplierValue.matches
                        .filter(filterNonApplied)
                        .filter(
                            (m) =>
                                m.job.workingHours &&
                                m.job.workingHours.length > 0
                        )
                        .map((m) =>
                            m.job!.workingHours.map((wh) => wh?.shiftType ?? 0)
                        )
                        .flat()
                        .filter((i) => i != 0);
                    filteredShiftTypes = new Set(allWorkingHours);
                    this.initialized = true;
                } else {
                    filteredGroupedCareType = careTypeValues;
                    filteredPositionType = positionValues;
                    filteredShiftTypes = new Set(
                        Object.keys(constants.workingHours).map((k) =>
                            parseInt(k)
                        )
                    );
                }

                const updatedValue: FilterSortCriteria = {
                    ...this.filterSubjectValue,
                    careTypes: filteredGroupedCareType,
                    positions: filteredPositionType,
                    shiftTypes: filteredShiftTypes,
                };

                this.fullSetCareTypes = filteredGroupedCareType;
                this.initialFilterSortCriteria$ = {
                    ...this.initialFilterSortCriteria$,
                    careTypes: filteredGroupedCareType,
                    positions: filteredPositionType,
                    shiftTypes: filteredShiftTypes,
                };
                this.updateFilters(updatedValue);
            }
        });
    }

    updateFilters(newFilters: FilterSortCriteria) {
        this.filtersSubject.next(newFilters);
    }

    public get filtersChanged() {
        return compareFilter(
            this.initialFilterSortCriteria$,
            this.filterSubjectValue
        );
    }

    resetFilters() {
        this.updateFilters(this.initialFilterSortCriteria);
    }

    getGroupedObject(extendedCareType: ExtendedConstantMap): GroupedFilterType {
        var values = Object.entries(extendedCareType ?? {}).reduce(
            (acc, [id, item]) => {
                if (!acc[item.parentLabel]) {
                    acc[item.parentLabel] = [];
                }
                acc[item.parentLabel].push({
                    id: +id,
                    label: item.label,
                    selected: true,
                });
                return acc;
            },
            {} as GroupedFilterType
        );
        return values;
    }
}
