import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import {
    PhoneVerificationService,
    VerifcationSendResult,
} from "src/shared/services/phone-number.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-phone-add",
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: "./phone-add.component.html",
    styleUrl: "./phone-add.component.scss",
})
export class PhoneAddComponent {
    formGroup!: FormGroup;
    errorMessage: string | null = null;
    loading = false;
    userExtId: string = "";
    contactExtId: string = "";
    agentContactVisible = false;

    constructor(
        private phoneVerificationService: PhoneVerificationService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        // parse route params
        this.route.params.subscribe((params) => {
            this.userExtId = params["extId"];
            this.contactExtId = params["contactId"];
        });
        // create form group
        this.formGroup = new FormGroup({
            phone: new FormControl("", [
                Validators.required,
                Validators.pattern("^(\\+ ?|0)[1-9][\\d ]{6,14}$"),
            ]),
        });
    }

    onSubmit() {
        if (this.formGroup.invalid) {
            return;
        }
        this.loading = true;
        const phone = this.formGroup.get("phone")?.value;
        this.phoneVerificationService
            .addPhoneNumber(this.userExtId, phone)
            .then((newContactExtId) => {
                // this.loading = false;
                this.phoneVerificationService
                    .sendPhoneVerificationCode(this.userExtId, newContactExtId)
                    .then((verificationSendResult) => {
                        if (
                            verificationSendResult ===
                            VerifcationSendResult.CodeSent
                        ) {
                            this.loading = false;
                            // navigate to verify page for new contact

                            this.router.navigateByUrl(
                                `verify/${this.userExtId}/${newContactExtId}`
                            );
                        }
                    });
            })
            .catch((error) => {
                // catch error of type ContactChangeTooLongAfterSignUpError
                if (error.name === "ContactChangeTooLongAfterSignUpError") {
                    this.agentContactVisible = true;
                }
                this.errorMessage = error.message;
                this.loading = false;
            });
    }
    goBack() {
        window.history.back();
    }

    callAgent() {
        window.location.href = `tel:${environment.app.agentPhoneNumber}`;
    }

    whatsappToAgent() {
        window.open(
            `https://api.whatsapp.com/send?phone=+497219588259&text=Ich%20%28${this.userExtId}%29%20m%C3%B6chte%20gerne%20meine%20Nummer%20ändern.`,
            "_blank"
        );
    }
}
