import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { MatchService } from "src/shared/services/match.service";
import { Applier, Match } from "../../../model";
import { getAvailableSize } from "src/shared/util/utilFunctions";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-match-applied",
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatDividerModule, MatIconModule],
    templateUrl: "./match-applied.component.html",
    styleUrl: "./match-applied.component.scss",
})
export class MatchAppliedComponent {
    @Input() match!: Match;
    @Input() applier!: Applier;

    applierExtId: string | null = null;

    constructor(
        private route: ActivatedRoute,
        private matchService: MatchService,
        private titleService: Title,

        private router: Router
    ) {
        var params = this.route.snapshot.paramMap;
        let extId = params.get("extId");
        let matchExtId = params.get("matchExtId");

        var matchApplier = this.matchService.matchApplierValue;

        if (matchApplier) {
            this.match = matchApplier.matches.find(
                (match) => match.extId == matchExtId
            )!;
            this.applier = matchApplier.applier!;
        }

        if (extId) {
            this.applierExtId = extId;
            this.matchService.getMatchApplierData(extId);
        }
    }
    ngOnInit(): void {
        this.titleService.setTitle(
            `${this.match.company.displayName} - Beworben`
        );

        const state = history.state;
        const value = state.key;
    }

    ngAfterViewChecked() {
        const availableSize = getAvailableSize();

        if (availableSize.usesFrame) {
            const widthDiff = window.innerWidth - availableSize.relevantWidth;
            const heightDiff =
                window.innerHeight - availableSize.relevantHeight;
            const headerElement: HTMLElement | null =
                document.querySelector(".header");
            if (
                headerElement != null &&
                !headerElement.hasAttribute("data-transformed")
            ) {
                var newDiff = widthDiff / 2;
                headerElement.style.left = `${newDiff}px`;
                headerElement.setAttribute("data-transformed", "true");
            }
        }
    }

    goBack() {
        this.router.navigate([`${this.applierExtId}`]);
    }

    contactAgent() {
        window.location.href = `tel:${environment.app.agentPhoneNumber}`;
    }
}
