<div class="main" style="display: flex">
    <div class="full-size-center-column" style="gap: 16px">
        <img
            loading="eager"
            src="https://assets.prd.heyflow.com/flows/laenk-signup-result/www/assets/fdddb59d-255e-4871-a3be-e645a3875b95/original.png"
            alt="Avatar"
            style="height: 100px; width: 100px"
        />
        <p style="text-align: center">
            Super, dass du mit länk deinen neuen Job finden möchtest! 🫶
        </p>
        <h2 style="text-align: center">
            Leider konnte unser Algorithmus keine passenden Jobs in deinem
            Umkreis finden.
        </h2>
        <p style="text-align: center">
            Gerne unterstützen wir dich aber zukünftig bei der Jobsuche und
            halten dich bei neuen Jobangeboten in deiner Region auf dem
            Laufenden.
        </p>
        <p style="text-align: center">Liebe Grüße,<br />Alice von länk 💜</p>
    </div>
</div>
