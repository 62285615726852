<div class="main" style="display: flex">
    <div class="full-size-center-column">
        <div class="row-center">
            <button
                mat-icon-button
                class="large-icon-button"
                (click)="goBack()"
            >
                <mat-icon>arrow_back</mat-icon>
            </button>
            <p class="title">Neue Telefonnummer hinzufügen</p>
        </div>

        @if (!this.agentContactVisible) {
        <form
            (ngSubmit)="onSubmit()"
            [formGroup]="this.formGroup"
            style="width: 100%"
        >
            <div class="column" style="gap: 8px">
                <mat-form-field
                    appearance="fill"
                    class="rounded-border"
                    style="width: 100%"
                    subscriptSizing="dynamic"
                >
                    <mat-label class="bigger-input">Telefonnummer</mat-label>
                    <input
                        matInput
                        inputmode="tel"
                        autocomplete="tel"
                        type="tel"
                        required
                        formControlName="phone"
                    />
                    <mat-error
                        >Gib bitte eine gültige Telefonnummer an.</mat-error
                    >
                </mat-form-field>
                <div *ngIf="errorMessage" class="error-message">
                    {{ errorMessage }}
                </div>

                <button mat-flat-button type="submit" [disabled]="this.loading">
                    <div class="row-center">
                        @if (this.loading) {
                        <div class="column-center">
                            <mat-spinner diameter="24" />
                        </div>
                        } Hinzufügen
                    </div>
                </button>
            </div>
        </form>
        } @else {
        <p>
            Kontaktiere bitte unseren Kundenservice, um deine Telefonnummer zu
            ändern.
        </p>
        <button
            mat-raised-button
            (click)="this.callAgent()"
            [disabled]="this.loading"
        >
            <mat-icon fontIcon="phone" />
            Anrufen
        </button>
        <button
            mat-raised-button
            (click)="this.whatsappToAgent()"
            [disabled]="this.loading"
            class="custom-green-btn"
        >
            <div class="row-center" style="padding: 12px 12px 12px 12px">
                <img src="assets/images/whatsapp.svg" height="20" width="20" />
                <p>WhatsApp schreiben</p>
            </div>
        </button>

        }
    </div>
</div>
