import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { UserService } from "src/shared/services/user.service";

@Component({
    selector: "app-home-risk",
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: "./home-risk.component.html",
    styleUrl: "./home-risk.component.scss",
})
export class HomeRiskComponent {
    constructor(private userService: UserService) {}

    openWhatsapp() {
        var extId = this.userService.getExtId();
        window.open(
            `https://api.whatsapp.com/send?phone=+497219588259&text=Hallo%20liebes%20l%C3%A4nk-Team%2C%20ich%20w%C3%BCrde%20mich%20%C3%BCber%20passende%20Jobvorschl%C3%A4ge%20in%20meiner%20Region%20freuen.%20Zum%20Datenabgleich%20sende%20ich%20euch%20meine%20Erkennungs-ID%3A%20${extId}.%20Viele%20Gr%C3%BC%C3%9Fe%20%3A%29`,
            "_blank"
        );
    }
}
