<div style="display: flex">
    <div class="column applied-content">
        <div class="column-center" style="gap: 8px">
            <div class="header">
                <img src="../../assets/images/lank-logo.png" height="56" />
            </div>
            <img
                style="width: 60px; object-fit: cover"
                src="{{
                    match.company.logo ??
                        'https://res.cloudinary.com/paulwvnjohi/image/upload/v1679492526/placeholder_sk48uq.png'
                }}"
            />
            <p class="message">
                {{ applier.user.firstName ?? "" }} - du hast dich beworben!
            </p>
            <p style="text-align: center">
                Der Anbieter wird sich in den nächsten Tagen bei dir melden! In
                der Zwischenzeit kannst du dich zurücklehnen und dir weitere
                Jobangebote ansehen...
            </p>
            <button mat-raised-button (click)="goBack()">
                <mat-icon fontIcon="add" />
                Weitere Jobs finden
            </button>
        </div>
        <mat-divider style="width: 90%; margin: 20px" />
        <div class="column-center" style="gap: 8px">
            <p style="font-size: 18px; font-weight: 500">
                Bewerbung beschleunigen
            </p>
            <p style="text-align: center">
                Du bist richtig motiviert und willst direkt weiter gehen? Klicke
                hier, um direkt Kontakt aufzunehmen
            </p>
            <button mat-raised-button (click)="this.contactAgent()">
                <mat-icon fontIcon="phone" />
                Direkt Kontakt aufnehmen
            </button>
        </div>
    </div>
</div>
